import { Tooltip } from '@material-ui/core';
import { makeStyles, Theme, fade, withStyles } from '@material-ui/core/styles';

export const redditStyledInputs = makeStyles((theme: Theme) => ({
    root: {
        border: '1px solid #e2e2e1',
        overflow: 'hidden',
        borderRadius: 4,
        backgroundColor: '#fcfcfb',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        '&:hover': {
            backgroundColor: '#fff',
        },
        '&$focused': {
            backgroundColor: '#fff',
            boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 2px`,
            borderColor: theme.palette.primary.main,
        },
    },
    focused: {},
}));

export const useStyles = makeStyles((theme: Theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        flexDirection: 'column',
    },
    addIcon: {
        marginRight: theme.spacing(1),
    },
    saveBtn: {
        marginTop: theme.spacing(2),
    },
    marginTop2: {
        marginTop: theme.spacing(2),
    },
    fixedWidthActionBtn: {
        width: 130,
    },
}));

export const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      backgroundColor: '#f5f5f9',
      color: 'rgba(0, 0, 0, 0.87)',
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
    },
  }))(Tooltip);
