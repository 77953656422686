import React, { useEffect, useContext, useState } from 'react'
import {
    Button,
    CircularProgress,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    Link as MuiLink,
} from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import DoneIcon from '@material-ui/icons/Done';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import LockOpenIcon from '@material-ui/icons/LockOpen';
import LockIcon from '@material-ui/icons/Lock';

import { redditStyledInputs, useStyles } from '../../lib/SharedMuiStyles'
import { updateOrder, getOrderByRef, deleteOrder } from '../../lib/firebaseHandler'
import { MainContext } from '../components/DashboardContext'
import EditItem from '../components/EditItem'
import { Order } from '../../lib/customTypes'
import Title from '../components/Title'


export default function OrderPage() {
    const [order, setOrder] = useState<Order | undefined>()
    const [loading, setLoading] = useState(true)
    const [orderNotFound, setOrderNotFound] = useState(false)
    const [editFieldsOpen, setEditFieldsOpen] = useState(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const [statusChangeAnchorEl, setStatusChangeAnchorEl] = useState<null | HTMLElement>(null)
    const params = useParams<Record<string, string | undefined>>()
    const { orderRef } = params
    const classes = useStyles()
    const redditStyles = redditStyledInputs()

    const mainContext = useContext(MainContext)
    const { changeContext, customers, orders, userAuth } = mainContext
    const history = useHistory()

    useEffect(() => {
        if (orders) {
            const currOrder = orders.find((order) => order.id === orderRef)
            setOrder(currOrder)
            setLoading(false)
        } else {
            getOrderByRef(userAuth.uid, orderRef).then((doc) => {
                if (doc.exists) {
                    // @ts-ignore
                    setOrder(doc.data())
                } else {
                    setOrderNotFound(true)
                    console.log('doc empty')
                }
                setLoading(false)
            })
        }
    }, [customers, orderRef, orders, userAuth])


    const listItems = [
        { label: 'תאריך ההזמנה', desc: '', value: order ? order.date instanceof Date ? order.date.toLocaleDateString('he-IL') : new Date(order.date.toDate()).toLocaleDateString('he-IL') : '', id: 'date', type: 'text', isHidden: true },
        { label: 'שם ההזמנה', desc: '', value: order ? order.orderName : '', id: 'orderName', type: 'text' },
        { label: 'מחיר', desc: '', value: order ? order.cost : '', id: 'cost', type: 'number' },
        { label: 'תיאור', desc: '', value: order ? order.description : '', id: 'description', type: 'text' },
        { label: 'לקוח', desc: '', value: order ? order.customerName : '', id: 'customerName', type: 'text', isDisabled: true },
        { label: 'הערות', desc: '', value: order ? order.notes : '', id: 'notes', type: 'text' },
        { label: 'סטטוס', desc: '', value: order ? order.isDone ? 'סגורה' : 'פתוחה' : '', id: 'isDone', type: 'text', isHidden: true },
    ]

    const submitUpdate = (updateObj) => {
        setLoading(true)
        updateOrder(userAuth.uid, orderRef, updateObj).then(() => {
            if (orders) {
                const updatedOrdersList = [...orders]
                const currOrder = updatedOrdersList.find((order) => order.id === orderRef)
                for (let key in updateObj) {
                    currOrder[key] = updateObj[key]
                }
                changeContext('orders', updatedOrdersList)
            } else {
                const currOrder = { ...order }
                for (let key in updateObj) {
                    currOrder[key] = updateObj[key]
                }
                setOrder(currOrder)
            }
            setLoading(false)
            setEditFieldsOpen(false)
        })
    }

    const handleOrderStatusChange = (isDone: boolean) => {
        if (isDone === order.isDone) {
            setStatusChangeAnchorEl(null)
            return
        }
        submitUpdate({ isDone })
        setStatusChangeAnchorEl(null)
    }

    const handleDeleteOrder = () => {
        setLoadingDelete(true)
        deleteOrder(userAuth.uid, orderRef, order.customer).then(() => {
            if (customers) {
                const updatedCustomersList = [...customers]
                const curCustomer = updatedCustomersList.find((customer) => {
                    return customer.fullRef === order.customer
                })
                for (let index in curCustomer.orders) {
                    if (curCustomer.orders[index].id === orderRef) {
                        curCustomer.orders.splice(parseInt(index), 1)
                        break;
                    }
                }
                changeContext('customers', updatedCustomersList)
            }
            if (orders) {
                const updatedOrdersList = [...orders]
                for (let index in updatedOrdersList) {
                    if (updatedOrdersList[index].id === order.id) {
                        updatedOrdersList.splice(parseInt(index), 1)
                    }
                }
                changeContext('orders', updatedOrdersList)
            }
            history.push('/dashboard/orders')
        })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant="body1" align="left">
                        <MuiLink component={Link} to="/dashboard/orders">
                            <Button color="primary" startIcon={<ChevronRightIcon />}>
                                חזרה לעמוד הזמנות / עבודות
                            </Button>
                        </MuiLink>
                    </Typography>
                    {loading && (
                        <Typography align="center" component="div">
                            <CircularProgress />
                        </Typography>
                    )}
                    {orderNotFound && (
                        <Typography variant="h6" align="center" color="secondary">
                            לצערנו ההזמנה לא נמצאה במערכת, אם אתם חושבים שזוהי טעות אנא דווחו לנו.
                        </Typography>
                    )}
                    {!loading && !orderNotFound && (
                        <Container>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title isPageTitle={true} align="center">
                                        {`עמוד הזמנה - ${order.orderName}`}
                                    </Title>
                                </Grid>
                                <Grid item xs={false} md={3} />
                                <Grid item xs={12} md={6}>
                                    {!editFieldsOpen && (
                                        <List disablePadding>
                                            {listItems.map((item) => (
                                                <React.Fragment key={`${item.id}-container`}>
                                                    <ListItem key={item.id}>
                                                        <ListItemText primary={item.label} secondary={item.desc} />
                                                        <Typography variant="body2" color="textPrimary">{item.value}</Typography>
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    )}
                                    {editFieldsOpen && (
                                        <EditItem
                                            fields={listItems}
                                            submitFunction={submitUpdate}
                                            TextFieldVariant="filled"
                                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                                            cancelCallback={() => { setEditFieldsOpen(false) }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Title align="center">
                                        פעולות בהזמנה
                                    </Title>
                                    <Typography component="div" align="center">
                                        <Button color="primary" startIcon={<EditIcon />} className={classes.fixedWidthActionBtn} onClick={() => setEditFieldsOpen(!editFieldsOpen)}>
                                            עריכת שדות
                                        </Button>
                                    </Typography>
                                    <Typography component="div" align="center">
                                        <Button color="primary" startIcon={<DoneIcon />} className={classes.fixedWidthActionBtn} onClick={(e) => setStatusChangeAnchorEl(e.currentTarget)}>
                                            שינוי סטטוס
                                        </Button>
                                        <Menu
                                            id="status-menu"
                                            anchorEl={statusChangeAnchorEl}
                                            keepMounted
                                            open={Boolean(statusChangeAnchorEl)}
                                            onClose={() => setStatusChangeAnchorEl(null)}
                                        >
                                            <MenuItem onClick={() => handleOrderStatusChange(true)}>
                                                <ListItemIcon>
                                                    <LockIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary="סגירת הזמנה" />
                                            </MenuItem>
                                            <MenuItem onClick={() => handleOrderStatusChange(false)}>
                                                <ListItemIcon>
                                                    <LockOpenIcon fontSize="small" />
                                                </ListItemIcon>
                                                <ListItemText primary="פתיחת הזמנה" />
                                            </MenuItem>
                                        </Menu>
                                    </Typography>
                                    <Typography component="div" align="center">
                                        <Button color="secondary" startIcon={<DeleteForeverIcon />} className={classes.fixedWidthActionBtn} onClick={() => { setDeleteWarning(true) }}>
                                            מחיקת הזמנה
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                </Paper>
                {!loading && !orderNotFound && (
                    <Dialog open={deleteWarning} maxWidth="sm" onClose={() => { setDeleteWarning(false) }} fullWidth>
                        <DialogTitle id="alert-dialog-title">{`אישור מחיקת הזמנה - ${order.orderName}`}</DialogTitle>
                        {!loadingDelete && (
                            <>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        מחיקה של ההזמנה אינה ניתנת לביטול ולא יהיה ניתן לשחזר את ההזמנה לאחר מכן.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { setDeleteWarning(false) }} color="secondary">
                                        ביטול
                                    </Button>
                                    <Button color="primary" onClick={() => { handleDeleteOrder() }}>
                                        אישור מחיקה
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                        {loadingDelete && (
                            <Typography component="div" align="center">
                                <CircularProgress />
                            </Typography>
                        )}
                    </Dialog>
                )}
            </Grid>
        </Grid>
    )
}