import firebase from 'firebase/app';
import { firestore } from './firebase'
import type { DocumentReference, DocumentData } from '@firebase/firestore-types'
import { Customer, Order } from './customTypes'

const usersRef = firestore.collection('users')


function getUserCollectionRef(userUID: string, collectionName: string) {
    const userRef = usersRef.doc(userUID)
    return userRef.collection(collectionName)
}

export async function addNewUser(userUID: string, userObj: { firstName: string, lastName: string, phone?: string }) {
    return usersRef.doc(userUID).set(userObj)
}

export async function getCustomerByRef(userUID: string, customerRef: string) {
    const usersCustomerRef = getUserCollectionRef(userUID, 'customers').doc(customerRef)
    return usersCustomerRef.get()
}

export async function getCustomers(userUID: string) {
    const usersCustomersRef = getUserCollectionRef(userUID, 'customers')
    const response = await usersCustomersRef.get()
    const customersArr = []
    if (!response.empty) {
        const { docs } = response
        docs.forEach((doc) => {
            const customerDoc = doc.data()
            customerDoc['ref'] = doc.id
            customerDoc['fullRef'] = doc.ref
            customersArr.push(customerDoc)
        })
    } else {
        console.log('doc empty')
    }
    return customersArr
}

export async function addCustomer(userUID: string, customerObj: Customer) {
    const usersCustomersRef = getUserCollectionRef(userUID, 'customers')
    const newCustomerRef = usersCustomersRef.doc()
    try {
        await newCustomerRef.set(customerObj)
    } catch (err) {
        return Promise.reject('Error adding customer')
    }
    return newCustomerRef
}

export async function updateCustomer(userUID: string, customerRef: string, updateObj: Customer) {
    const usersCustomerRef = getUserCollectionRef(userUID, 'customers')
    return usersCustomerRef.doc(customerRef).update(updateObj)
}

export async function deleteCustomer(userUID: string, customerRefStr: string, customerOrders: DocumentReference<DocumentData>[]) {
    const usersCustomerRef = getUserCollectionRef(userUID, 'customers')
    const customerRef = usersCustomerRef.doc(customerRefStr)
    await customerRef.delete()
    const writeBatch = firestore.batch()
    for (let orderRef of customerOrders) {
        writeBatch.delete(orderRef)
    }
    return writeBatch.commit()
}

export async function getOrders(userUID: string) {
    const usersOrdersRef = getUserCollectionRef(userUID, 'orders')
    const response = await usersOrdersRef.orderBy('date', 'asc').get()
    const ordersArr = []
    if (!response.empty) {
        const { docs } = response
        docs.forEach((doc) => {
            const orderObj = doc.data()
            orderObj.id = doc.id
            orderObj.date = orderObj.date.toDate()
            ordersArr.push(orderObj)
        })
    } else {
        console.log('doc empty')
    }
    return ordersArr
}

export async function getOrderByRef(userUID: string, orderRef: string) {
    const usersOrderRef = getUserCollectionRef(userUID, 'orders').doc(orderRef)
    return usersOrderRef.get()
}

export async function getOrdersByCustomer(userUID: string, customerRef) {
    const usersOrdersRef = getUserCollectionRef(userUID, 'orders')
    return usersOrdersRef.where('customer', '==', customerRef).get()
}

export async function addOrder(userUID: string, orderObj: Order) {
    const usersOrdersRef = getUserCollectionRef(userUID, 'orders')
    const usersCustomerRef = typeof orderObj.customer === 'string' ? firestore.doc(orderObj.customer) : orderObj.customer
    if (typeof orderObj.customer === 'string') {
        orderObj.customer = firestore.doc(orderObj.customer)
    }
    await usersOrdersRef.doc(`${orderObj.id}`).set(orderObj)
    return usersCustomerRef.update({ orders: firebase.firestore.FieldValue.arrayUnion(firestore.doc(`users/${userUID}/orders/${orderObj.id}`)) })
}

export async function updateOrder(userUID: string, orderRef: string, updateObj: Order) {
    const usersOrdersRef = getUserCollectionRef(userUID, 'orders')
    return usersOrdersRef.doc(orderRef).update(updateObj)
}

export async function deleteOrder(userUID: string, orderRefStr: string, customerRef: DocumentReference<DocumentData> | string) {
    const usersOrdersRef = getUserCollectionRef(userUID, 'orders')
    const orderRef = usersOrdersRef.doc(orderRefStr)
    const usersCustomerRef = typeof customerRef === 'string' ? firestore.doc(customerRef) : customerRef
    await orderRef.delete()
    return usersCustomerRef.update({ orders: firebase.firestore.FieldValue.arrayRemove(orderRef) })
}
