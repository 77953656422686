import { FormEvent, useEffect, useState, useContext } from 'react'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import {
    CircularProgress,
    Typography,
    Fab,
    Button,
    TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { useHistory } from 'react-router-dom'

import { getCustomers, addCustomer } from '../../lib/firebaseHandler'
import MuiTable from '../components/MuiTable'
import Title from '../components/Title';
import { Customer } from '../../lib/customTypes'
import { redditStyledInputs, useStyles } from '../../lib/SharedMuiStyles'
import { MainContext } from '../components/DashboardContext'

const tableColumns = [
    {
        name: 'customerName',
        label: 'שם לקוח',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'email',
        label: 'אימייל',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'phone',
        label: 'טלפון',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'address',
        label: 'כתובת',
        options: {
            filter: true,
            sort: true,
        },
    },
]


export function AddCustomerModalContent(props: { closeModalCallback: Function }) {
    const { closeModalCallback } = props
    const [addCustomerState, setAddCustomerState] = useState({
        address: '',
        email: '',
        customerName: '',
        phone: '',
        notes: '',
        orders: [],
        id: '',
    })
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(null)
    const mainContext = useContext(MainContext)
    const { changeContext, customers, userAuth } = mainContext

    const handleStateChange = (key: string, value: string | number) => {
        setAddCustomerState((previousState) => ({
            ...previousState,
            [key]: value,
        }))
    }

    const redditStyles = redditStyledInputs()
    const classes = useStyles()

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        addCustomer(userAuth.uid, addCustomerState)
            .then((response) => {
                const newCustomer = { ...addCustomerState, ref: response.id, fullRef: response }
                const newCustomersArr = [...customers, newCustomer]
                changeContext('customers', newCustomersArr)
                setLoading(false)
                setSuccess(true)
                setTimeout(() => {
                    closeModalCallback(false)
                }, 10000)
            })
            .catch((res) => {
                console.error(res)
                setLoading(false)
                setSuccess(false)
            })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {loading && (
                    <Typography component="div" align="center">
                        <CircularProgress />
                    </Typography>
                )}
                {success && (
                    <Typography variant="body1" color="textPrimary" align="center">
                        הלקוח נוסף בהצלחה! החלון ייסגר בתוך 10 שניות (ניתן גם לסגור ידנית)
                    </Typography>
                )}
                {(!success && success !== null) && (
                    <Typography variant="body1" color="secondary" align="center">
                        קרתה תקלה בעת שמירת הלקוח, אנא נסו שנית במועד מאוחר יותר
                    </Typography>
                )}
                {(!loading && !success) && (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="שם לקוח"
                            value={addCustomerState.customerName}
                            onChange={(e) => { handleStateChange('customerName', e.target.value) }}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="אימייל"
                            value={addCustomerState.email}
                            onChange={(e) => { handleStateChange('email', e.target.value) }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            type="number"
                            label="מספר זיהוי (ת.ז. / ח.פ.)"
                            value={addCustomerState.id}
                            onChange={(e) => { handleStateChange('id', e.target.value) }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="טלפון"
                            value={addCustomerState.phone}
                            onChange={(e) => { handleStateChange('phone', e.target.value) }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="כתובת"
                            value={addCustomerState.address}
                            onChange={(e) => { handleStateChange('address', e.target.value) }}
                            fullWidth
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="הערות"
                            value={addCustomerState.notes}
                            onChange={(e) => { handleStateChange('notes', e.target.value) }}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <Typography component="div" align="center">
                            <Button variant="contained" color="primary" className={classes.saveBtn} type="submit" onClick={(e) => handleSubmit(e)}>
                                שמירת לקוח חדש
                            </Button>
                        </Typography>
                    </form>
                )}
            </Grid>
        </Grid>
    )
}

export default function Customers(props) {
    const startingArr: Customer[] = []
    const [customersState, setCustomersState] = useState(startingArr)
    const [addCustomerOpen, setAddCustomerOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const mainContext = useContext(MainContext)
    const { changeContext, customers, userAuth } = mainContext
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        if (customers) {
            setCustomersState(customers)
            setLoading(false)
        } else {
            getCustomers(userAuth.uid).then((customersArr) => {
                changeContext('customers', customersArr)
                setCustomersState(customersArr)
                setLoading(false)
            })
        }
    }, [changeContext, customers, userAuth])

    const handleRowClick = (rowData, rowMeta) => {
        history.push(`/dashboard/customers/${customersState[rowMeta.dataIndex].ref}`)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Title align="center" isPageTitle={true}>
                        ניהול לקוחות
                    </Title>
                    {loading && (
                        <Typography component="div" align="center">
                            <CircularProgress />
                        </Typography>
                    )}
                    {!loading && (
                        <MuiTable
                            data={customersState}
                            isHeb={true}
                            columns={tableColumns}
                            setRowProps={() => ({ style: { cursor: 'pointer' } })}
                            title={(
                                <Fab aria-label="add customer" color="primary" variant="extended" onClick={() => { setAddCustomerOpen(true) }}>
                                    <AddIcon className={classes.addIcon} />
                                    הוספת לקוח
                                </Fab>
                            )}
                            onRowClick={handleRowClick}
                        />
                    )}
                    <Dialog open={addCustomerOpen} onClose={() => setAddCustomerOpen(false)} maxWidth="xs" fullWidth>
                        <DialogTitle id="add-customer-modal-title">הוספת לקוח למערכת</DialogTitle>
                        <DialogContent>
                            <AddCustomerModalContent closeModalCallback={setAddCustomerOpen} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { setAddCustomerOpen(false) }} color="primary">
                                סגירה
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    )
}
