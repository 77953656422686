import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import { useHistory, Link as RouterLink } from 'react-router-dom'
import firebase from 'firebase/app'
import { CircularProgress } from '@material-ui/core';
import { auth } from '../lib/firebase'

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            כל הזכויות שמורות ©
            {' '}
            <Link color="inherit" href="https://mydashboard.co.il/">
                MyDashboard.co.il
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    paper: {
        marginTop: theme.spacing(8),
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
    },
    avatar: {
        margin: theme.spacing(1),
        backgroundColor: theme.palette.secondary.main,
    },
    form: {
        width: '100%', // Fix IE 11 issue.
        marginTop: theme.spacing(1),
    },
    submit: {
        margin: theme.spacing(3, 0, 2),
    },
}));

export default function SignIn() {
    const classes = useStyles();
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [loading, setLoading] = useState(false)
    const [wrongDetails, setWrongDetails] = useState(false)
    const [wrongEmail, setWrongEmail] = useState(false)
    const [error, setError] = useState(false)
    const [notVerifiedYet, setNotVerifiedYet] = useState(false)
    const [rememberMe, setRememberMe] = useState(false)
    const history = useHistory()
    
    const handleSubmit = (e) => {
        setLoading(true)
        e.preventDefault()
        auth.setPersistence(rememberMe ? firebase.auth.Auth.Persistence.LOCAL : firebase.auth.Auth.Persistence.SESSION)
        auth.signInWithEmailAndPassword(email, password).then((userCreds) => {
            if (userCreds.user.emailVerified) {
                setLoading(false)
                history.push('/dashboard')
            } else {
                auth.signOut()
                setNotVerifiedYet(true)
                setLoading(false)
            }
        }).catch((err) => {
            console.log(err)
            setLoading(false)
            if (err.code === 'auth/wrong-password') {
                setWrongDetails(true)
            } else if (err.code === 'auth/invalid-email')  {
                setWrongEmail(true)
            } else {
                setError(true)
            }
        })
    }
    return (
        <Container component="main" maxWidth="xs">
            <CssBaseline />
            <div className={classes.paper}>
                <Avatar className={classes.avatar}>
                    <LockOutlinedIcon />
                </Avatar>
                <Typography component="h1" variant="h5">
                    כניסה למערכת
                </Typography>
                <form className={classes.form} noValidate>
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        id="email"
                        label="כתובת אימייל"
                        name="email"
                        autoComplete="email"
                        autoFocus
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                    />
                    <TextField
                        variant="outlined"
                        margin="normal"
                        required
                        fullWidth
                        name="password"
                        label="סיסמה"
                        type="password"
                        id="password"
                        autoComplete="current-password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <FormControlLabel
                        control={<Checkbox value="remember" color="primary" checked={rememberMe} onChange={(e) => setRememberMe(e.target.checked)} />}
                        label="זכור אותי"
                    />
                    {wrongDetails && (
                        <Typography variant="body1" color='secondary' align="center">
                            כתובת האימייל או הסיסמה אינם נכונים
                        </Typography>
                    )}
                    {wrongEmail && (
                        <Typography variant="body1" color='secondary' align="center">
                            נא להזין כתובת אימייל תקנית
                        </Typography>
                    )}
                    {error && (
                        <Typography variant="body1" color='secondary' align="center">
                            קרתה תקלה בעת הכניסה למערכת, אנא נסו שנית בעוד מספר רגעים
                        </Typography>
                    )}
                    {notVerifiedYet && (
                        <Typography variant="body1" color='secondary' align="center">
                            כתובת האימייל לא אומתה עדיין. אנא לחצו על קישור האימות במיייל שנשלח אליכם.
                        </Typography>
                    )}
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        onClick={(e) => handleSubmit(e)}
                        disabled={loading}
                    >
                        כניסה
                    </Button>
                    <Grid container>
                        <Grid item xs>
                            <Link href="#" variant="body2">
                                שכחת את הסיסמה?
                            </Link>
                        </Grid>
                        <Grid item>
                            <Link component={RouterLink} to="/signup" variant="body2">
                                אין לכם חשבון? הרשמו כעת
                            </Link>
                        </Grid>
                        {loading && (
                            <Grid item xs={12}>
                                <Typography component="div" align="center">
                                    <CircularProgress />
                                </Typography>
                            </Grid>
                        )}
                    </Grid>
                </form>
            </div>
            <Box mt={8}>
                <Copyright />
            </Box>
        </Container>
    );
}



