import React, { useEffect, useContext, useState } from 'react';
import { useTheme } from '@material-ui/core/styles';
import { LineChart, Line, XAxis, YAxis, Label, ResponsiveContainer } from 'recharts';
import { CircularProgress, Typography } from '@material-ui/core';

import { MainContext } from './DashboardContext'
import Title from './Title';
import type { Timestamp } from '@firebase/firestore-types'

// Generate Sales Data
function createData(date: string, amount: number) {
  return { date, amount };
}

type ChartData = { date: string, amount: number }[]

export default function Chart() {
  const theme = useTheme();
  const [chartData, setChartData] = useState<ChartData>([])
  const mainContext = useContext(MainContext)
  const { orders, changeContext, globalLoading } = mainContext

  useEffect(() => {
    const lessThan30Days = (orderDate: Date | Timestamp): string | undefined => {
      if (orderDate instanceof Date) {
        const isLessThan30Days = new Date().getTime() - orderDate.getTime() < 2.628e+9
        if (isLessThan30Days) {
          return orderDate.toLocaleDateString('he-IL')
        }
      } else {
        const isLessThan30Days = new Date().getTime() - orderDate.toMillis() < 2.628e+9
        if (isLessThan30Days) {
          return new Date(orderDate.toDate()).toLocaleDateString('he-IL')
        }
      }
    }

    const dayExistsAlready = (dateStr: string, arr: ChartData) => {
      const dayIndex = arr.findIndex((value) => value.date === dateStr)
      return dayIndex
    }

    if (!globalLoading && orders) {
      const dataArr: ChartData = []
      for (let order of orders) {
        const dateStr = lessThan30Days(order.date)
        const dayIndex = dayExistsAlready(dateStr, dataArr)
        if (dateStr && dayIndex === -1) {
          dataArr.push(createData(dateStr, Number(order.cost)))
        } else if (dateStr) {
          dataArr[dayIndex].amount += Number(order.cost)
        }
      }
      setChartData(dataArr)
    }
  }, [changeContext, globalLoading, orders])

  return (
    <React.Fragment>
      <Title>סכומי הזמנות מהחודש האחרון</Title>
      {globalLoading && (
        <Typography component="div" align="center">
          <CircularProgress />
        </Typography>
      )}
      {!chartData.length && (
        <>
          <Typography variant="h6" align="center" color="textPrimary">
            עדיין לא נוספו הזמנות למערכת
          </Typography>
          <Typography variant="body1" align="center" color="textPrimary">
            לאחר הוספת הזמנות למערכת, הגרף יתעדכן
          </Typography>
        </>
      )}
      {!globalLoading && !!chartData.length && (
        <ResponsiveContainer>
          <LineChart
            data={chartData}
            margin={{
              top: 16,
              right: 16,
              bottom: 0,
              left: 24,
            }}
          >
            <XAxis dataKey="date" stroke={theme.palette.text.secondary} />
            <YAxis stroke={theme.palette.text.secondary}>
              <Label
                angle={270}
                position="left"
                style={{ textAnchor: 'middle', fill: theme.palette.text.primary }}
              >
                הזמנות (ש"ח)
              </Label>
            </YAxis>
            <Line type="monotone" dataKey="amount" stroke={theme.palette.primary.main} dot={false} />
          </LineChart>
        </ResponsiveContainer>
      )}
    </React.Fragment>
  );
}
