import { Container, Grid, Paper, Typography, Toolbar, makeStyles, Link, Button, Box } from "@material-ui/core";
import { Link as RouterLink } from 'react-router-dom'
import AppBar from '@material-ui/core/AppBar';
// import MenuIcon from '@material-ui/icons/Menu'
import React, { useEffect } from "react";

import Title from "../dashboard/components/Title";

function Copyright() {
    return (
        <Typography variant="body2" color="textSecondary" align="center">
            כל הזכויות שמורות ©
            {' '}
            <Link color="inherit" href="https://mydashboard.co.il/">
                MyDashboard.co.il
        </Link>{' '}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const useStyles = makeStyles((theme) => ({
    toolbar: {
        paddingRight: 24, // keep right padding when drawer closed
    },
    appBar: {
        zIndex: theme.zIndex.drawer + 1,
    },
    menuButton: {
        marginRight: 36,
    },
    title: {
        flexGrow: 1,
    },
    appBarSpacer: theme.mixins.toolbar,
    mainContent: {
        padding: theme.spacing(2),
    },
    marginTop2: {
        marginTop: theme.spacing(2),
    },
}));

export default function Home() {
    const classes = useStyles()

    useEffect(() => {
        const htmlElmnt = window.document.getElementsByTagName('html')[0]
        htmlElmnt.style.overflow = 'visible'
        return () => {
            htmlElmnt.style.overflow = ''
        }
    }, [])
    return (
        <>
            <AppBar position="fixed" className={classes.appBar}>
                <Toolbar className={classes.toolbar}>
                    {/* <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="open drawer"
                        onClick={() => { }}
                        className={classes.menuButton}
                    >
                        <MenuIcon />
                    </IconButton> */}
                    <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
                        MyDashboard.co.il
                    </Typography>
                </Toolbar>
            </AppBar>
            <div className={classes.appBarSpacer} />
            <Container component="main" className={classes.mainContent}>
                <Paper>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Title isPageTitle align="center">
                                ברוכים הבאים ל - MyDashboard.co.il
                            </Title>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="textPrimary" align="center">
                                מיי דאשבורד היא מערכת ניהול העסק (CRM) הראשונה
                                {' '}
                                <b>
                                    החינמית
                                </b>
                                {' '}
                                לחלוטין בעברית.
                            </Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <Grid container spacing={2} className={classes.marginTop2}>
                                <Grid item xs={false} md={3} />
                                <Grid item xs={12} md={3}>
                                    <Typography component="div" align="center">
                                        <Link component={RouterLink} to="/signin">
                                            <Button variant="contained" color="primary">
                                                כניסה למערכת
                                            </Button>
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Typography component="div" align="center">
                                        <Link component={RouterLink} to="/signup">
                                            <Button variant="contained" color="primary">
                                                הרשמה למערכת
                                            </Button>
                                        </Link>
                                    </Typography>
                                </Grid>
                                <Grid item xs={false} md={3} />
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="body1" color="textPrimary" align="center" className={classes.marginTop2}>
                                המערכת הינה בשלבי הרצה ולכן יתכנו באגים שונים. אם נתקלתם בבאג, נשמח אם תדווחו לנו בכתובת האימייל שלנו על כך.
                                <br />
                                <Link href="mailto:contact@yanivbarzily.com">contact@yanivbarzily.com</Link>
                            </Typography>
                            <Typography variant="body1" color="textPrimary" align="center" className={classes.marginTop2} component="div">
                                <Title align="center">משתמש ניסיון</Title>
                                מעוניינים להתנסות במערכת מבלי להירשם? היכנסו למערכת עם הפרטים הבאים:
                                <br />
                                אימייל: mydashboard.test.user@gmail.com
                                <br />
                                סיסמה: mydashboard
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Paper className={classes.marginTop2}>
                    <Grid container>
                        <Grid item xs={12}>
                            <Title isPageTitle align="center">
                                ממשק פשוט ונוח ובעברית!
                            </Title>
                            <Typography component="div" align="center" color="textPrimary" variant="subtitle1">
                                העמוד הראשי של המערכת:
                                <br />
                                <img src='/DashboardScreen.jpg' alt="מסך ראשי דאשבורד" style={{ width: '80%' }} />
                            </Typography>
                        </Grid>
                    </Grid>
                </Paper>
                <Box pt={4}>
                    <Copyright />
                </Box>
            </Container>
        </>
    )
}