import React, { Context } from 'react'
import { Customer, Order } from '../../lib/customTypes'
import type { User } from '@firebase/auth-types'

export type AppMainContext = {
    customers: Customer[] | null,
    orders: Order[] | null,
    changeContext: (key: string, value: any) => void,
    globalLoading: boolean,
    userAuth: User | undefined,
}

export const MainContext: Context<AppMainContext> = React.createContext({
    customers: null,
    orders: null,
    changeContext: (key: string, value) => {},
    globalLoading: Boolean(true),
    userAuth: undefined,
})
