import React, { useEffect, useContext, useState } from 'react'
import {
    Button,
    Fab,
    CircularProgress,
    Container,
    Divider,
    Grid,
    List,
    ListItem,
    ListItemText,
    Paper,
    Typography,
    Link as MuiLink,
} from '@material-ui/core'
import { Link, useParams, useHistory } from 'react-router-dom'
import AddIcon from '@material-ui/icons/Add';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContentText from '@material-ui/core/DialogContentText';
import ChevronRightIcon from '@material-ui/icons/ChevronRight';
import EditIcon from '@material-ui/icons/Edit';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';

import { redditStyledInputs, useStyles } from '../../lib/SharedMuiStyles'
import { getCustomerByRef, getOrdersByCustomer, updateCustomer, deleteCustomer } from '../../lib/firebaseHandler'
import { MainContext } from '../components/DashboardContext'
import EditItem from '../components/EditItem'
import { Customer } from '../../lib/customTypes'
import Title from '../components/Title'
import { tableColumns } from './Orders'
import MuiTable from '../components/MuiTable'
import { AddOrderModalContent } from './Orders'


export default function CustomerPage() {
    const [customer, setCustomer] = useState<Customer | undefined>()
    const [loading, setLoading] = useState(true)
    const [customersOrders, setCustomersOrders] = useState([])
    const [customerNotFound, setCustomerNotFound] = useState(false)
    const [addOrderModalOpen, setAddOrderModalOpen] = useState(false)
    const [editFieldsOpen, setEditFieldsOpen] = useState(false)
    const [deleteWarning, setDeleteWarning] = useState(false)
    const [loadingDelete, setLoadingDelete] = useState(false)
    const params = useParams<Record<string, string | undefined>>()
    const { customerRef } = params
    const classes = useStyles()
    const redditStyles = redditStyledInputs()

    const mainContext = useContext(MainContext)
    const { changeContext, customers, orders, userAuth } = mainContext
    const history = useHistory()

    useEffect(() => {
        const getCustomerOrders = (userUID: string, docRef) => {
            if (orders) {
                const ordersArr = orders.filter((order) => {
                    if (!order.customer) {
                        // @ts-ignore
                        return order.customerObj.ref === docRef.id
                    } else {
                        // @ts-ignore
                        return (order.customer.id === docRef.id)
                    }
                })
                setCustomersOrders(ordersArr)
                setLoading(false)
            } else {
                getOrdersByCustomer(userUID, docRef).then((response) => {
                    if (!response.empty) {
                        const ordersArr = setOrdersFromCustomer(response.docs)
                        setCustomersOrders(ordersArr)
                    } else {
                        console.log('doc empty')
                    }
                    setLoading(false)
                })
            }
        }

        if (customers) {
            const curCustomer = customers.find((customer) => customer.ref === customerRef)
            if (!curCustomer) {
                setCustomerNotFound(true)
                setLoading(false)
            } else {
                setCustomer(curCustomer)
                getCustomerOrders(userAuth.uid, curCustomer.fullRef)
                setLoading(false)
            }
        } else {
            setLoading(true)
            getCustomerByRef(userAuth.uid, customerRef).then((doc) => {
                if (doc.exists) {
                    const customerDoc = doc.data()
                    getCustomerOrders(userAuth.uid, doc.ref)
                    // @ts-ignore
                    setCustomer(customerDoc)
                } else {
                    setCustomerNotFound(true)
                    setLoading(false)
                }
            })
        }
    }, [customers, customerRef, orders, userAuth])


    const setOrdersFromCustomer = (orders) => {
        const ordersArr = []
        orders.forEach((doc) => {
            const orderObj = doc.data()
            orderObj.id = doc.id
            ordersArr.push(orderObj)
        })
        return ordersArr
    }

    const listItems = [
        { label: 'שם מלא', desc: '', value: customer ? customer.customerName : '', id: 'customerName', type: 'text' },
        { label: 'אימייל', desc: '', value: customer ? customer.email : '', id: 'email', type: 'text' },
        { label: 'טלפון', desc: '', value: customer ? customer.phone : '', id: 'phone', type: 'text' },
        { label: 'כתובת', desc: '', value: customer ? customer.address : '', id: 'address', type: 'text' },
        { label: "מס' זיהוי", desc: '', value: customer ? customer.id : '', id: 'id', type: 'text' },
        { label: 'הערות', desc: '', value: customer ? customer.notes : '', id: 'notes', type: 'text' },
    ]

    const submitUpdate = (updateObj) => {
        setLoading(true)
        updateCustomer(userAuth.uid, customerRef, updateObj).then(() => {
            if (customers) {
                const updatedCustomersList = [...customers]
                const curCustomer = updatedCustomersList.find((customer) => customer.ref === customerRef)
                console.log(curCustomer, customers)
                for (let key in updateObj) {
                    curCustomer[key] = updateObj[key]
                }
                changeContext('customers', updatedCustomersList)
            } else {
                const curCustomer = { ...customer }
                for (let key in updateObj) {
                    curCustomer[key] = updateObj[key]
                }
                setCustomer(curCustomer)
            }
            setLoading(false)
            setEditFieldsOpen(false)
        })
    }

    const handleDeleteCustomer = () => {
        setLoadingDelete(true)
        deleteCustomer(userAuth.uid, customerRef, customer.orders).then(() => {
            if (customers) {
                const updatedCustomersList = [...customers]
                let indexToRemove: number
                updatedCustomersList.find((customer, index) => {
                    const bool = customer.ref === customerRef
                    if (bool) {
                        indexToRemove = index
                    }
                    return bool
                })
                updatedCustomersList.splice(indexToRemove, 1)
                changeContext('customers', updatedCustomersList)
            }
            if (orders) {
                const updatedOrdersList = [...orders]
                for (let index in updatedOrdersList) {
                    for (let customerOrder of customersOrders) {
                        if (updatedOrdersList[index].id === customerOrder.id) {
                            updatedOrdersList.splice(parseInt(index), 1)
                        }
                    }
                }
                changeContext('orders', updatedOrdersList)
            }
            history.push('/dashboard/customers')
        })
    }

    const handleRowClick = (rowData, rowMeta) => {
        history.push(`/dashboard/orders/${customersOrders[rowMeta.dataIndex].id}`)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Typography variant="body1" align="left">
                        <MuiLink component={Link} to="/dashboard/customers">
                            <Button color="primary" startIcon={<ChevronRightIcon />}>
                                חזרה לעמוד לקוחות
                            </Button>
                        </MuiLink>
                    </Typography>
                    {loading && (
                        <Typography align="center" component="div">
                            <CircularProgress />
                        </Typography>
                    )}
                    {customerNotFound && (
                        <Typography variant="h6" align="center" color="secondary">
                            לצערנו הלקוח לא נמצא במערכת, אם אתם חושבים שזוהי טעות אנא דווחו לנו.
                        </Typography>
                    )}
                    {!loading && !customerNotFound && (
                        <Container>
                            <Grid container>
                                <Grid item xs={12}>
                                    <Title isPageTitle={true} align="center">
                                        {`עמוד לקוח - ${customer.customerName}`}
                                    </Title>
                                </Grid>
                                <Grid item xs={false} md={3} />
                                <Grid item xs={12} md={6}>
                                    {!editFieldsOpen && (
                                        <List disablePadding>
                                            {listItems.map((item) => (
                                                <React.Fragment key={`${item.id}-container`}>
                                                    <ListItem key={item.id}>
                                                        <ListItemText primary={item.label} secondary={item.desc} />
                                                        <Typography variant="body2" color="textPrimary">{item.value}</Typography>
                                                    </ListItem>
                                                    <Divider />
                                                </React.Fragment>
                                            ))}
                                        </List>
                                    )}
                                    {editFieldsOpen && (
                                        <EditItem
                                            fields={listItems}
                                            submitFunction={submitUpdate}
                                            TextFieldVariant="filled"
                                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                                            cancelCallback={() => { setEditFieldsOpen(false) }}
                                        />
                                    )}
                                </Grid>
                                <Grid item xs={12} md={3}>
                                    <Title align="center">
                                        פעולות בכרטיס לקוח
                                    </Title>
                                    <Typography component="div" align="center">
                                        <Button color="primary" startIcon={<EditIcon />} className={classes.fixedWidthActionBtn} onClick={() => setEditFieldsOpen(!editFieldsOpen)}>
                                            עריכת שדות
                                        </Button>
                                    </Typography>
                                    <Typography component="div" align="center">
                                        <Button color="secondary" startIcon={<DeleteForeverIcon />} className={classes.fixedWidthActionBtn} onClick={() => { setDeleteWarning(true) }}>
                                            מחיקת לקוח
                                        </Button>
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Container>
                    )}
                </Paper>
                {!loading && !customerNotFound && (
                    <Paper className={`${classes.paper} ${classes.marginTop2}`}>
                        <Title align="center">
                            {`ההזמנות של ${customer.customerName}`}
                        </Title>
                        <MuiTable
                            data={customersOrders}
                            isHeb={true}
                            columns={tableColumns}
                            setRowProps={() => ({ style: { cursor: 'pointer' } })}
                            title={(
                                <Fab aria-label="add order" color="primary" variant="extended" onClick={() => { setAddOrderModalOpen(true) }}>
                                    <AddIcon className={classes.addIcon} />
                                    הוספת הזמנה / עבודה
                                </Fab>
                            )}
                            sortOrder={{ name: 'date', direction: 'desc' }}
                            onRowClick={handleRowClick}
                        />
                        <Dialog open={addOrderModalOpen} onClose={() => setAddOrderModalOpen(false)} maxWidth="xs" fullWidth>
                            <DialogTitle id="add-customer-modal-title">הוספת הזמנה / עבודה למערכת</DialogTitle>
                            <DialogContent>
                                <AddOrderModalContent closeModalCallback={setAddOrderModalOpen} customerNameProp={customer.customerName} customerObjProp={customer} />
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={() => { setAddOrderModalOpen(false) }} color="primary">
                                    סגירה
                                </Button>
                            </DialogActions>
                        </Dialog>
                    </Paper>
                )}
                {!loading && !customerNotFound && (
                    <Dialog open={deleteWarning} maxWidth="sm" onClose={() => { setDeleteWarning(false) }} fullWidth>
                        <DialogTitle id="alert-dialog-title">{`אישור מחיקת לקוח - ${customer.customerName}`}</DialogTitle>
                        {!loadingDelete && (
                            <>
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description">
                                        מחיקה של הלקוח תוביל גם למחיקת כל ההזמנות המשויכות ללקוח. פעולה זו אינה ניתנת לביטול ולא יהיה ניתן לשחזר את הלקוח לאחר מכן.
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button onClick={() => { setDeleteWarning(false) }} color="secondary">
                                        ביטול
                                    </Button>
                                    <Button color="primary" onClick={() => handleDeleteCustomer()}>
                                        אישור מחיקה
                                    </Button>
                                </DialogActions>
                            </>
                        )}
                        {loadingDelete && (
                            <Typography component="div" align="center">
                                <CircularProgress />
                            </Typography>
                        )}
                    </Dialog>
                )}
            </Grid>
        </Grid>
    )
}