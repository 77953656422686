import React, { useEffect, useState } from 'react';
import { StylesProvider, jssPreset, createMuiTheme, ThemeProvider, } from '@material-ui/core/styles';
import rtl from 'jss-rtl';
import { create } from 'jss';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';

import './App.css';
import Dashboard from './dashboard/Dashboard';
import Home from './LandingPage/Home'
import SignIn from './LandingPage/SignIn'
import SignUp from './LandingPage/SignUp'
import { MainContext } from './dashboard/components/DashboardContext'
import type { AppMainContext } from './dashboard/components/DashboardContext'
import { auth } from './lib/firebase';

// Configure JSS
const jss = create({ plugins: [...jssPreset().plugins, rtl()] });
const theme = createMuiTheme({
  direction: 'rtl', // Both here and <body dir="rtl">
  typography: {
    fontFamily: [
      'Heebo',
      'Roboto',
      'Helvetica',
      'Arial',
      'sans-serif',
    ].join(','),
  },
})

function App() {
  const [mainContext, setMainContext] = useState<AppMainContext>({
    customers: null,
    orders: null,
    changeContext: (key: string, value) => { },
    globalLoading: false,
    userAuth: undefined,
  })
  const [lastURL, setLastURL] = useState('/dashboard')
  const [isFirstRender, setIsFirstRender] = useState(true)
  
  useEffect(() => {
    let isMounted = true
    const changeContext = (key, value) => {
      setMainContext(m => ({
        ...m,
        [key]: value,
      }))
    }
    setMainContext(m => ({
      ...m,
      changeContext,
    }))
    auth.onAuthStateChanged((user) => {
      // TODO: need to remember to clean the orders and customers in case the user signs out
      user && isMounted ? changeContext('userAuth', user) : changeContext('userAuth', null)
    })
    return () => {
      isMounted = false
    }
  }, [])
  if (isFirstRender) {
    const location = window.location.pathname
    if (location === '/signin' || location === '/') {
      setLastURL('/dashboard')
    } else {
      setLastURL(location)
    }
    setIsFirstRender(false)
  }
  return (
    <StylesProvider jss={jss}>
      <ThemeProvider theme={theme}>
        <MainContext.Provider value={mainContext}>
          <Router>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route exact path="/signin">
                {(!mainContext.userAuth || !mainContext.userAuth.emailVerified) ? <SignIn /> : <Redirect to={lastURL} />}
              </Route>
              <Route exact path="/signup">
                {!mainContext.userAuth ? <SignUp /> : <Redirect to={mainContext.userAuth.emailVerified ? lastURL : '/signin'} />}
              </Route>
              <Route path="/dashboard/">
                {(mainContext.userAuth && mainContext.userAuth.emailVerified) ? <Dashboard /> : <Redirect to="/signin" />}
              </Route>
            </Switch>
          </Router>
        </MainContext.Provider>
      </ThemeProvider>
    </StylesProvider>
  );
}

export default App;
