import React, { useEffect, useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Chart from '../components/Chart';
import Deposits from '../components/Deposits';
import DashboardFabs from '../components/DashboardFabs';
import { makeStyles } from '@material-ui/core/styles';
import clsx from 'clsx';

import { getOrders, getCustomers } from '../../lib/firebaseHandler'
import { MainContext } from '../components/DashboardContext'


const useStyles = makeStyles((theme) => ({
    paper: {
        padding: theme.spacing(2),
        display: 'flex',
        overflow: 'auto',
        flexDirection: 'column',
    },
    fixedHeight: {
        height: 240,
    },
}));

export default function DashboardMain(props) {
    const classes = useStyles();
    const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);
    const mainContext = useContext(MainContext)
    const { changeContext, orders, customers, userAuth } = mainContext

    useEffect(() => {
        if (orders) {
            changeContext('globalLoading', false)
        } else {
            changeContext('globalLoading', true)
            getOrders(userAuth.uid).then((ordersArr) => {
                changeContext('orders', ordersArr)
                changeContext('globalLoading', false)
            })
        }
    }, [changeContext, orders, userAuth])

    useEffect(() => {
        if (customers) {
            changeContext('globalLoading', false)
        } else {
            changeContext('globalLoading', true)
            getCustomers(userAuth.uid).then((customersArr) => {
                changeContext('customers', customersArr)
            })
        }
    }, [changeContext, customers, userAuth])

    return (
        <Grid container spacing={3}>
            {/* Chart */}
            <Grid item xs={12} md={8} lg={9}>
                <Paper className={fixedHeightPaper}>
                    <Chart />
                </Paper>
            </Grid>
            {/* Recent Deposits */}
            <Grid item xs={12} md={4} lg={3}>
                <Paper className={fixedHeightPaper}>
                    <Deposits />
                </Paper>
            </Grid>
            {/* Recent Orders */}
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <DashboardFabs />
                </Paper>
            </Grid>
        </Grid>
    )
}