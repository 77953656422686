import React, { useState, useEffect } from 'react'
import { Button, TextField, Typography } from '@material-ui/core'
import { makeStyles, Theme } from '@material-ui/core/styles'

type Field = {
    label: string,
    id: string,
    type: string,
    value?: string | number,
    isDisabled?: boolean,
    isHidden?: boolean,
}

type EditItemProps = {
    fields: Field[],
    submitFunction: (fieldsStateObj) => void,
    cancelCallback?: () => void,
    InputProps?: Record<any, any>,
    TextFieldVariant?: 'standard' | 'filled' | 'outlined',
}

const useStyles = makeStyles((theme: Theme) => ({
    margin: {
        marginRight: theme.spacing(2),
        marginLeft: theme.spacing(2),
        marginTop: theme.spacing(2),
    },
}))

export default function EditItem(props: EditItemProps) {
    const [state, setState] = useState({})
    const {
        fields,
        submitFunction,
        InputProps,
        TextFieldVariant,
        cancelCallback,
    } = props
    const classes = useStyles()

    useEffect(() => {
        const newState = {}
        for (let field of fields) {
            if (field.isHidden) {
                continue
            }
            newState[field.id] = field.value || ''
        }
        setState((prevState) => ({ ...prevState, ...newState }))
    }, [fields])

    const handleStateChange = (key: string, value: string) => {
        setState((prevState) => ({ ...prevState, [key]: value }))
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        submitFunction(state)
    }

    return (
        <form onSubmit={(e) => handleSubmit(e)}>
            {!!fields.length && fields.map((field) => (
                <React.Fragment key={field.id}>
                    {!field.isHidden && (
                        <TextField
                            key={field.id + 'TextField'}
                            type={field.type}
                            id={field.id}
                            label={field.label}
                            variant={TextFieldVariant || 'outlined'}
                            InputProps={InputProps || {}}
                            value={state[field.id] || ''}
                            onChange={(e) => { handleStateChange(e.target.id, e.target.value) }}
                            fullWidth
                            margin="dense"
                            disabled={field.isDisabled}
                        />
                    )}
                </React.Fragment>
            ))}
            {!!fields.length && (
                <Typography component="div" align="center">
                    {cancelCallback && (
                        <Button variant="contained" color="secondary" type="button" onClick={() => cancelCallback()} className={classes.margin}>
                            ביטול
                        </Button>
                    )}
                    <Button variant="contained" color="primary" type="submit" onClick={(e) => handleSubmit(e)} className={classes.margin}>
                        עדכון
                    </Button>
                </Typography>
            )}
        </form>
    )
}
