
import PropTypes from 'prop-types'
import MUIDataTable from 'mui-datatables';

export default function MuiTable(props) {
    const {
        allowFilter,
        filterType,
        responsive,
        allowDownload,
        expandableRows,
        expandableRowsOnClick,
        selectableRows,
        elevation,
        sortOrder,
        onRowClick,
        onRowExpansionChange,
        setRowProps,
        otherOptions,
        columns,
        data,
        isHeb,
        title,
    } = props

    const tableOptions = {
        ...otherOptions,
        filter: allowFilter,
        filterType,
        responsive,
        download: allowDownload,
        expandableRows,
        expandableRowsHeader: false,
        expandableRowsOnClick,
        selectableRows,
        elevation,
        sortOrder,
        onRowClick,
        onRowExpansionChange,
        selectToolbarPlacement: 'none',
        selectableRowsOnClick: false,
        setRowProps,
        textLabels: !isHeb ? {} : {
            body: {
                noMatch: 'טרם נוספו למערכת',
                toolTip: 'מיון',
                columnHeaderTooltip: (column) => `מיון לפי ${column.label}`,
            },
            pagination: {
                rowsPerPage: 'שורות בכל עמוד',
                displayRows: 'מתוך',
            },
            toolbar: {
                search: 'חיפוש בתוצאות',
                downloadCsv: 'הורדה בפורמט CSV',
                print: 'הדפסה',
                viewColumns: 'עמודות להצגה',
                filterTable: 'מיון תוצאות',
            },
            filter: {
                all: 'הכל',
                title: 'מיון תוצאות',
                reset: 'איפוס',
            },
            viewColumns: {
                title: 'עמודות להצגה',
                titleAria: 'הצג/הסתר עמודות',
            },
        },
    };

    return (
        <MUIDataTable data={data} columns={columns} options={tableOptions} title={title} />
    )
}

MuiTable.propTypes = {
    otherOptions: PropTypes.object,
    filterType: PropTypes.oneOf(['checkbox', 'dropdown', 'multiselect', 'textField', 'custom']),
    allowFilter: PropTypes.bool,
    responsive: PropTypes.oneOf(['vertical', 'standard', 'simple']),
    allowDownload: PropTypes.bool,
    expandableRows: PropTypes.bool,
    expandableRowsOnClick: PropTypes.bool,
    selectableRows: PropTypes.oneOf(['none', 'multiple', 'single']),
    elevation: PropTypes.number,
    sortOrder: PropTypes.oneOfType([
        PropTypes.shape({
            name: PropTypes.string.isRequired,
            direction: PropTypes.oneOf(['desc', 'asc']).isRequired,
        }),
        PropTypes.shape({}),
    ]),
    onRowClick: PropTypes.func,
    onRowExpansionChange: PropTypes.func,
    setRowProps: PropTypes.func,
    columns: PropTypes.arrayOf(PropTypes.shape({
        name: PropTypes.string.isRequired,
        label: PropTypes.string.isRequired,
        options: PropTypes.object,
    })).isRequired,
    data: PropTypes.arrayOf(PropTypes.object).isRequired,
    isHeb: PropTypes.bool,
    title: PropTypes.oneOfType([PropTypes.string, PropTypes.node])
}

MuiTable.defaultProps = {
    otherOptions: {},
    filterType: 'dropdown',
    responsive: 'standard',
    allowFilter: true,
    allowDownload: false,
    expandableRows: false,
    expandableRowsOnClick: false,
    selectableRows: 'none',
    elevation: 0,
    sortOrder: {},
    onRowClick: (rowData, rowMeta) => {},
    onRowExpansionChange: (curExpanded, allExpanded, rowsExpanded) => {},
    setRowProps: () => ({}),
    isHeb: false,
    title: '',
}
