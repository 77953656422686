import { FormEvent, useEffect, useState, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import AddIcon from '@material-ui/icons/Add';
import {
    CircularProgress,
    Typography,
    Fab,
    Button,
    TextField,
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import Autocomplete from '@material-ui/lab/Autocomplete';

import { getOrders, addOrder, getCustomers } from '../../lib/firebaseHandler'
import MuiTable from '../components/MuiTable'
import Title from '../components/Title';
import { Order, Customer } from '../../lib/customTypes'
import { redditStyledInputs, useStyles } from '../../lib/SharedMuiStyles'
import { MainContext } from '../components/DashboardContext'

export const tableColumns = [
    {
        name: 'date',
        label: 'תאריך',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return new Date(value instanceof Date ? value : value.toDate()).toLocaleDateString('he-IL')
            },
        },
    },
    {
        name: 'orderName',
        label: 'שם ההזמנה',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'description',
        label: 'תיאור',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'customerName',
        label: 'לקוח',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'cost',
        label: 'מחיר',
        options: {
            filter: true,
            sort: true,
        },
    },
    {
        name: 'isDone',
        label: 'סטטוס',
        options: {
            filter: true,
            sort: true,
            customBodyRender: (value, tableMeta, updateValue) => {
                return value ? 'סגורה' : 'פתוחה'
            },
        },
    },
]

export function AddOrderModalContent(props: { closeModalCallback: Function, customerNameProp?: string, customerObjProp?: Customer }) {
    const { closeModalCallback, customerNameProp, customerObjProp } = props
    let customerObj: Customer = customerObjProp || null
    const [addOrderState, setAddOrderState] = useState({
        customerName: customerNameProp || '',
        customerObj,
        orderName: '',
        date: new Date(),
        cost: '',
        description: '',
        isDone: false,
        notes: '',
        id: '',
    })
    const [loading, setLoading] = useState(false)
    const [loadingCustomers, setLoadingCustomers] = useState(true)
    const [success, setSuccess] = useState(null)
    const mainContext = useContext(MainContext)
    const { changeContext, orders, customers, userAuth } = mainContext

    const handleStateChange = (key: string, value: string | number | Customer) => {
        setAddOrderState((previousState) => ({
            ...previousState,
            [key]: value,
        }))
    }

    useEffect(() => {
        if (!orders) {
            setLoading(true)
            getOrders(userAuth.uid).then((ordersArr) => {
                changeContext('orders', ordersArr)
                setLoading(false)
            })
        } else {
            setAddOrderState((prevState) => {
                const lastOrderById = !orders.length ? { id: '0' } : orders.reduce((a, b) => {
                    if (parseInt(a.id) > parseInt(b.id)) {
                        return a
                    }
                    return b
                })
                const newState = {
                    ...prevState,
                    id: `${parseInt(lastOrderById.id) + 1}`,
                }
                return newState
            })
        }
    }, [changeContext, orders, userAuth])

    useEffect(() => {
        if (customers) {
            setLoadingCustomers(false)
        } else {
            getCustomers(userAuth.uid).then((customersArr) => {
                changeContext('customers', customersArr)
                setLoading(false)
            })
        }
    }, [changeContext, customers, userAuth])


    const redditStyles = redditStyledInputs()
    const classes = useStyles()

    const handleSubmit = (e: FormEvent) => {
        e.preventDefault()
        setLoading(true)
        const orderObj = { ...addOrderState, customer: addOrderState.customerObj.fullRef }
        delete orderObj.customerObj
        addOrder(userAuth.uid, orderObj)
            .then(() => {
                const objToAdd = { ...addOrderState, customer: addOrderState.customerObj.fullRef }
                const newOrdersArr = [...orders, objToAdd]
                changeContext('orders', newOrdersArr)
                setLoading(false)
                setSuccess(true)
                setTimeout(() => {
                    closeModalCallback(false)
                }, 10000)
            })
            .catch((res) => {
                console.error(res)
                setLoading(false)
                setSuccess(false)
            })
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                {loading && (
                    <Typography component="div" align="center">
                        <CircularProgress />
                    </Typography>
                )}
                {success && (
                    <Typography variant="body1" color="textPrimary" align="center">
                        ההזמנה נוספה בהצלחה! החלון ייסגר בתוך 10 שניות (ניתן גם לסגור ידנית)
                    </Typography>
                )}
                {(!success && success !== null) && (
                    <Typography variant="body1" color="secondary" align="center">
                        קרתה תקלה בעת שמירת ההזמנה, אנא נסו שנית במועד מאוחר יותר
                    </Typography>
                )}
                {(!loading && !success) && (
                    <form onSubmit={(e) => handleSubmit(e)}>
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="שם ההזמנה"
                            value={addOrderState.orderName}
                            onChange={(e) => { handleStateChange('orderName', e.target.value) }}
                            fullWidth
                            required
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="מחיר"
                            value={addOrderState.cost}
                            onChange={(e) => { handleStateChange('cost', e.target.value) }}
                            fullWidth
                            margin="normal"
                            required
                        />
                        <Autocomplete
                            id="customersListAutocomplete"
                            options={customers}
                            // open={autoCompleteOpen}
                            inputValue={addOrderState.customerName}
                            onInputChange={(e, value) => { handleStateChange('customerName', value) }}
                            getOptionLabel={(option) => option.customerName}
                            // style={{ width: 300 }}
                            loading={loadingCustomers}
                            value={addOrderState.customerObj}
                            onChange={(e, newValue) => {
                                handleStateChange('customerObj', newValue)
                            }}
                            disabled={!customers.length || !!customerNameProp}
                            renderInput={(params) => (
                                <TextField
                                    // eslint-disable-next-line react/jsx-props-no-spreading
                                    {...params}
                                    variant="filled"
                                    margin="normal"
                                    required
                                    fullWidth
                                    name="selectedCustomer"
                                    label="לקוח"
                                    type="text"
                                    id="selectedCustomer"
                                    InputProps={{
                                        ...params.InputProps,
                                        classes: redditStyles,
                                        disableUnderline: true,
                                        endAdornment: (
                                            <>
                                                {loadingCustomers ? <CircularProgress color="inherit" size={20} /> : null}
                                            </>
                                        ),
                                    }}
                                />
                            )}
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="תיאור"
                            value={addOrderState.description}
                            onChange={(e) => { handleStateChange('description', e.target.value) }}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <TextField
                            InputProps={{ classes: redditStyles, disableUnderline: true }}
                            variant="filled"
                            label="הערות"
                            value={addOrderState.notes}
                            onChange={(e) => { handleStateChange('notes', e.target.value) }}
                            fullWidth
                            multiline
                            margin="normal"
                        />
                        <Typography component="div" align="center">
                            <Button variant="contained" color="primary" className={classes.saveBtn} type="submit" onClick={(e) => handleSubmit(e)}>
                                שמירת הזמנה / עבודה חדשה
                            </Button>
                        </Typography>
                    </form>
                )}
            </Grid>
        </Grid>
    )
}

export default function Orders() {
    const startingArr: Order[] = []
    const [ordersState, setOrdersState] = useState(startingArr)
    const [addOrderOpen, setAddOrderOpen] = useState(false)
    const [loading, setLoading] = useState(true)
    const mainContext = useContext(MainContext)
    const { changeContext, orders, userAuth } = mainContext
    const classes = useStyles()
    const history = useHistory()

    useEffect(() => {
        if (orders) {
            setOrdersState(orders)
            setLoading(false)
        } else {
            getOrders(userAuth.uid).then((ordersArr) => {
                changeContext('orders', ordersArr)
                setOrdersState(ordersArr)
                setLoading(false)
            })
        }
    }, [changeContext, orders, userAuth])

    const handleRowClick = (rowData, rowMeta) => {
        history.push(`/dashboard/orders/${ordersState[rowMeta.dataIndex].id}`)
    }

    return (
        <Grid container>
            <Grid item xs={12}>
                <Paper className={classes.paper}>
                    <Title align="center" isPageTitle={true}>
                        ניהול הזמנות / עבודות
                    </Title>
                    {loading && (
                        <Typography component="div" align="center">
                            <CircularProgress />
                        </Typography>
                    )}
                    {!loading && (
                        <MuiTable
                            data={ordersState}
                            isHeb={true}
                            columns={tableColumns}
                            setRowProps={() => ({ style: { cursor: 'pointer' } })}
                            title={(
                                <Fab aria-label="add order" color="primary" variant="extended" onClick={() => { setAddOrderOpen(true) }}>
                                    <AddIcon className={classes.addIcon} />
                                    הוספת הזמנה / עבודה
                                </Fab>
                            )}
                            sortOrder={{ name: 'date', direction: 'desc' }}
                            onRowClick={handleRowClick}
                        />
                    )}
                    <Dialog open={addOrderOpen} onClose={() => setAddOrderOpen(false)} maxWidth="xs" fullWidth>
                        <DialogTitle id="add-customer-modal-title">הוספת הזמנה / עבודה למערכת</DialogTitle>
                        <DialogContent>
                            <AddOrderModalContent closeModalCallback={setAddOrderOpen} />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => { setAddOrderOpen(false) }} color="primary">
                                סגירה
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Paper>
            </Grid>
        </Grid>
    )
}