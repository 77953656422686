import React, { useState, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Container, Grid, TextField, Typography } from '@material-ui/core';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Dialog from '@material-ui/core/Dialog';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';

import Title from './Title';
import { AddOrderModalContent } from '../DashboardPages/Orders'
import { AddCustomerModalContent } from '../DashboardPages/Customers'
import { MainContext } from './DashboardContext'
import { HtmlTooltip } from '../../lib/SharedMuiStyles'

const useStyles = makeStyles((theme) => ({
  container: {
    display: 'flex',
    flexDirection: 'column',
  },
  marginTop3: {
    marginTop: theme.spacing(3),
  },
}));

export default function DashboardFabs() {
  const [addOrderOpen, setAddOrderOpen] = useState(false)
  const [addCustomerOpen, setAddCustomerOpen] = useState(false)
  const classes = useStyles();
  const mainContext = useContext(MainContext)
  const { customers } = mainContext
  return (
    <React.Fragment>
      <Title>ביצוע פעולות במערכת</Title>
      <Container maxWidth="md" className={classes.container}>
        <Grid container spacing={2}>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={4}>
            <TextField type="text" label="חיפוש לקוחות (בקרוב)" helperText="חיפוש חופשי לפי כל פרמטר" fullWidth disabled />
          </Grid>
          <Grid item xs={false} md={2} />
          <Grid item xs={12} md={4}>
            <TextField type="text" label="חיפוש הזמנות / עבודות (בקרוב)" helperText="חיפוש חופשי לפי כל פרמטר" fullWidth disabled />
          </Grid>
          <Grid item xs={false} md={1} />
        </Grid>
        <Grid container spacing={2} className={classes.marginTop3}>
          <Grid item xs={false} md={1} />
          <Grid item xs={12} md={4}>
            <Button variant="contained" color="primary" fullWidth onClick={() => { setAddCustomerOpen(true) }} >
              <AddCircleIcon htmlColor="white" />
              <span style={{ flexGrow: 1 }}>
                הוספת לקוח
              </span>
            </Button>
          </Grid>
          <Grid item xs={false} md={2} />
          <Grid item xs={12} md={4}>
            <HtmlTooltip
              title={(
                <Typography color="inherit">ניתן להוסיף הזמנה לאחר הוספת לקוח</Typography>
              )}
              open={!customers || !customers.length}
              arrow
              placement="bottom"
            >
              <Button variant="contained" color="primary" fullWidth onClick={() => { setAddOrderOpen(true) }} disabled={!customers || !customers.length} >
                <AddCircleIcon style={{ position: 'relative', left: 5 }} htmlColor="white" />
                <span style={{ flexGrow: 1 }}>
                  הוספת הזמנה / עבודה
                </span>
              </Button>
            </HtmlTooltip>
          </Grid>
          <Grid item xs={false} md={1} />
        </Grid>
      </Container>
      <Dialog open={addOrderOpen} onClose={() => setAddOrderOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle id="add-customer-modal-title">הוספת הזמנה / עבודה למערכת</DialogTitle>
        <DialogContent>
          <AddOrderModalContent closeModalCallback={setAddOrderOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAddOrderOpen(false) }} color="primary">
            סגירה
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog open={addCustomerOpen} onClose={() => setAddCustomerOpen(false)} maxWidth="xs" fullWidth>
        <DialogTitle id="add-customer-modal-title">הוספת לקוח למערכת</DialogTitle>
        <DialogContent>
          <AddCustomerModalContent closeModalCallback={setAddCustomerOpen} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { setAddCustomerOpen(false) }} color="primary">
            סגירה
          </Button>
        </DialogActions>
      </Dialog>
    </React.Fragment>
  );
}
