import React, { useContext } from 'react';
import { Link as RouterLink } from 'react-router-dom'
import Link from '@material-ui/core/Link';
import { makeStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { CircularProgress } from '@material-ui/core';

import Title from './Title';
import { MainContext } from './DashboardContext'

const useStyles = makeStyles((theme) => ({
  depositContext: {
    flex: 1,
  },
  ordersLink: {
    marginTop: theme.spacing(2)
  }
}));

export default function Deposits() {
  const classes = useStyles();
  const mainContext = useContext(MainContext)
  const { globalLoading, orders } = mainContext
  const latestOrder = orders ? orders[orders.length - 1] : null
  return (
    <>
      <Title>הזמנה אחרונה</Title>
      {globalLoading && (
        <Typography component="div" align="center">
          <CircularProgress />
        </Typography>
      )}
      {!latestOrder && (
        <Typography color="textPrimary">
          עדיין לא נוספו הזמנות למערכת
        </Typography>
      )}
      {!globalLoading && latestOrder && (
        <>
          <Typography component="p" variant="h4">
            {Number(latestOrder.cost).toLocaleString('he-IL')}
            {' '}
            ש"ח
          </Typography>
          <Typography color="textSecondary" className={classes.depositContext}>
            מתאריך
            {' '}
            {latestOrder.date instanceof Date && latestOrder.date.toLocaleDateString('he-IL')}
          </Typography>
        </>
      )}
      <div className={classes.ordersLink}>
        <Link component={RouterLink} color="primary" to="/orders">
          עמוד הזמנות
        </Link>
      </div>
    </>
  );
}
