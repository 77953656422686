import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';

const firebaseConfig = {
    apiKey: "AIzaSyDhmf6h2g68qYtg_xL4vkeLf72trf398yw",
    authDomain: "mydashboard-co-il.firebaseapp.com",
    projectId: "mydashboard-co-il",
    storageBucket: "mydashboard-co-il.appspot.com",
    messagingSenderId: "270052278275",
    appId: "1:270052278275:web:518be8d37b8ec870f78e36",
    measurementId: "G-8TY3DSP5H7"
};

firebase.initializeApp(firebaseConfig);

// export const analytics = firebase.analytics();
export const auth = firebase.auth();
export const firestore = firebase.firestore();
